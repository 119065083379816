/**
 * Nuxt middleware for preventing users from accessing
 * admin routes.
 * @example
 * ```ts
 * setup() {
 *   definePageMeta({ layout: 'default', middleware: ['admin'] });
 * }
 * ```
 * @package fun-academy-campus
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const currentUser = useCurrentUser();
  const { $logger } = useNuxtApp();
  if (!currentUser) {
    return await navigateTo(
      useLocalePath()({
        name: 'sign-out',
        query: { skip: 'loading', redirect: to.fullPath },
      })
    );
  }
  const { getUserClaims, getUserRoles } = useFirebase();
  const roles = await getUserRoles();
  const adminRole = roles.find((role) => 'Admin' === role.name);
  const userRoles = (await getUserClaims())?.roles;
  const isAllowed = Boolean(userRoles?.includes(String(adminRole?.id)));
  if (isAllowed) {
    $logger.info('Access to admin granted.');
  } else {
    $logger.warn('Access to admin denied.');
    return false;
  }
});
